<template>
  <div class="story-search">
    <storySearchCategoryBar
      :categories="mainCategories"
      :selected-category="mainSelectedCategory"
      :select-category="selectCategory"
      :clear-category="clearCategory"
      class="category-bar--main"
      show-clear-list
    >
      <story-search-filters-dropdown
        :language.sync="computedLanguageFilter"
        :country.sync="filterByCountry"
        @update:language="search({ clearStories: true })"
        @update:country="search({ clearStories: true })"
      />
      <newsroom-search-dropdown />
    </storySearchCategoryBar>
    <storySearchCategoryBar
      v-if="mainSelectedCategory"
      :categories="subCategories"
      :selected-category="subSelectedCategory"
      :select-category="selectCategory"
      :clear-category="clearCategory"
      class="category-bar--sub"
      show-more
    />
    <storySearchCategoryBar
      v-if="subSelectedCategory"
      :categories="specCategories"
      :selected-category="specSelectedCategory"
      :select-category="selectCategory"
      :clear-category="clearCategory"
      class="category-bar--spec"
      show-more
    />
  </div>
</template>

<script>
import _last from 'lodash/last'
import _pick from 'lodash/pick'
import _values from 'lodash/values'
import _pickBy from 'lodash/pickBy'
import _debounce from 'lodash/debounce'
import _dropRight from 'lodash/dropRight'

import StorySearchCategoryBar from '@/components/storySearch/StorySearchCategoryBar'
import NewsroomSearchDropdown from '@/components/storySearch/NewsroomSearchDropdown'
import StorySearchFiltersDropdown from '@/components/storySearch/StorySearchFiltersDropdown'

import { mapMutations, mapGetters, mapActions } from 'vuex'

import { persist, load } from '@/shared/utils'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'

function paginationFactory () {
  return {
    hasMore: false,
    cursor: null
  }
}

const sanitizedQueryParams = [
  'main_category',
  'sub_category',
  'spec_category',
  'story_language',
  'story_country',
  'sort',
  'newsroom'
]

export default {
  components: {
    StorySearchCategoryBar,
    NewsroomSearchDropdown,
    StorySearchFiltersDropdown
  },

  props: {
    newsroomId: {
      type: Number,
      default: null
    },
    queryLimit: {
      type: Number,
      default: 5
    }
  },

  data () {
    return {
      pagination: paginationFactory(),
      showMoreSubCats: false,
      filterByLanguage: load('filterStoriesByLanguage', null),
      filterByCountry: null,
      cancelToken: null
    }
  },

  computed: {
    ...mapGetters({
      selectedCategories: 'selectedStoryCategories',
      mainCategories: 'mainCategories',
      subCategories: 'subCategories',
      specCategories: 'specCategories',
      mainSelectedCategory: 'mainSelectedCategory',
      subSelectedCategory: 'subSelectedCategory',
      specSelectedCategory: 'specSelectedCategory'
    }),

    /**
     * Sanitized list of query params from the URL
     * @return {object}
     */
    routeQueryParams () {
      const query = this.$route.query
      return _pick(query, sanitizedQueryParams)
    },

    /**
     * The filtered by language
     * @type {string}
     */
    computedLanguageFilter: {
      get () {
        return this.filterByLanguage
      },
      set (value) {
        persist('filterStoriesByLanguage', value)
        this.filterByLanguage = value
      }
    },

    /**
     * The persisted map of query params
     * @return {object}
     */
    localQueryParams () {
      // use pickBy to remove null values
      return _pickBy({
        main_category: this.mainSelectedCategory ? this.mainSelectedCategory.id : null,
        sub_category: this.subSelectedCategory ? this.subSelectedCategory.id : null,
        spec_category: this.specSelectedCategory ? this.specSelectedCategory.id : null,
        story_language: this.computedLanguageFilter,
        story_country: this.filterByCountry
      }, q => q)
    },

    /**
     * Returns the pagination params object
     * @returns {object}
     */
    paginationParams () {
      let cursorParams = {}

      this.pagination.cursor && (cursorParams.cursor = this.pagination.cursor)

      return cursorParams
    }
  },

  watch: {
    '$route': function (newVal, oldVal) {
      if (newVal.name === 'stories' && this.$isEmpty(newVal.query)) {
        this.clearCategory(0)
      }
    }
  },

  async mounted () {
    await this.shouldFetchCategories()
    this.syncRouteParamsToLocal()
    this.fetchStoriesDebounced()
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    /**
     * Sets up the local parameters based on the ones in the route query
     */
    syncRouteParamsToLocal () {
      const q = this.routeQueryParams
      q.main_category && this.setSelectedCategoryByIDInStore({ category: q.main_category, level: 0 })
      q.sub_category && this.setSelectedCategoryByIDInStore({ category: q.sub_category, level: 1 })
      q.spec_category && this.setSelectedCategoryByIDInStore({ category: q.spec_category, depth: 2 })
      q.story_language && (this.computedLanguageFilter = q.story_language) // set the computed so it gets persisted (saved in localStorage)
      q.story_country && (this.filterByCountry = q.story_country)
    },
    /**
     * Changes the URL and on success fetches the new stories
     */
    search ({ clearStories = false }) {
      if (clearStories) {
        this.clearPagination()
        this.setStories([])
      }
      this.$router.push({
        query: this.localQueryParams
      }, () => {
        this.fetchStoriesDebounced()
      }, () => {
        this.fetchStoriesDebounced()
      })
    },
    /**
     * Fetches the stories based on the selected properties
     * @param {Array} categories - Array of selected categories
     * @return {Promise<any>}
     */
    fetchStories (categories) {
      categories = categories || _values(this.selectedCategories).filter(category => category)

      const categoryId = categories.length === 0
        ? null
        : _last(categories).id

      this.toggleFetching(true)

      this.cancelToken && this.cancelToken.cancel()
      this.cancelToken = this.$api.cancelToken()

      const params = {
        include: 'newsroom.country',
        category: categoryId,
        newsrooms: [this.newsroomId].filter(Boolean),
        sort: 'relevance',
        ...this.paginationParams,
        language: this.computedLanguageFilter,
        country: this.filterByCountry,
        limit: this.queryLimit
      }
      return StoryApiService.fetchStories({
        params: _pickBy(params, q => q), // none null ones
        cancelToken: this.cancelToken.token
      })
        .then(response => {
          let stories = response.data.data
          const hasStories = stories && stories.length > 0
          if (hasStories) {
            this.appendStories(stories)

            this.pagination.hasMore = response.data.meta.has_more
            this.pagination.cursor = response.data.meta.cursor

            this.$emit('paginationChange', this.pagination)

            return this.toggleFetching(false)
          }

          const subselection = _dropRight(categories, 1)
          if (!this.$isEmpty(subselection)) {
            return this.fetchStories(subselection)
          }

          this.pagination.hasMore = false
          this.$emit('paginationChange', this.pagination)
        })
        .catch((error) => {
          if (this.$api.isCancelToken(error)) return
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.toggleFetching(false)
        })
    },

    /**
     * Fetches stories in a debounced manner
     */
    fetchStoriesDebounced: _debounce(function (categories) {
      this.fetchStories(categories)
    }, 300),
    /**
     * Convenience method to fetch next stories. Called from parent via $ref
     * @returns {*}
     */
    loadNext () {
      return this.fetchStoriesDebounced()
    },

    /**
     * Resets the pagination
     */
    clearPagination () {
      this.pagination = paginationFactory()
    },

    /**
     * Allows clearing a category
     * @param depth - depth of the category to clear. From 0 to 2
     */
    clearCategory (depth) {
      this.$bus.$emit('StorySearchCategoryBar:hideAllCategoriesDropdown')
      this.clearSelectedCategoriesFromThisLevelUp(depth)
      this.search({ clearStories: true })
    },

    /**
     * Selects a category from the list. Allows for optional level pass
     * @param {object} category
     * @param {number} level
     */
    selectCategory (category, level = 0) {
      level = (category && category.depth) ? category.depth : level
      this.$ma.trackEvent({
        category: 'storySearch',
        action: 'categoryClick',
        label: `cat_level_${level}`,
        value: category.name
      })
      // Check if the selected category is selected, stop execution if true
      if (this.selectedCategories[level] === category) {
        return
      }
      this.setSelectedCategoryInStore({ category, level })
      this.clearSelectedCategoriesFromThisLevelUp(level + 1)
      this.search({ clearStories: true })
    },
    ...mapMutations({
      setSelectedCategoryInStore: 'SELECT_CATEGORY',
      setSelectedCategoryByIDInStore: 'SELECT_CATEGORY_BY_ID',
      toggleFetching: 'TOGGLE_FETCHING_STORIES',
      appendStories: 'APPEND_STORIES',
      setStories: 'SET_STORIES'
    }),
    ...mapActions(['shouldFetchCategories', 'clearSelectedCategoriesFromThisLevelUp'])
  }
}
</script>
<style lang="scss">
@import "~utils";

$categoryBarItemPadding: $spacing-small;

.story-search {
  margin-bottom: $margin-large;
  position: relative;

  .selected-category {
    color: $grey-lighter;
    text-transform: uppercase;
    padding-left: $categoryBarItemPadding;
    font-size: 1rem;
    flex: 0 1 auto;
    margin-right: auto;
    position: relative;

    &.is-active .selected-category__name:after {
      content: $hf-chevron-up;
    }

    &__name {
      &.selected {
        font-weight: 600;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        content: $hf-chevron-down;
        font-family: 'Hypefactors-Icon-Font';
        font-size: 0.8rem;
      }
    }
  }

  .category-bar {
    position: relative;
    border-top: $hf__border--base;

    a:not(.dropdown-item) {
      color: $white;
    }

    &--main {
      background: $hf__color-gray-darker;

      .category-bar__list-wrap {
        background: $hf__color-gray-darker;
        border-top: 1px solid darken($grey-dark, 5%);
      }
    }

    &--sub {
      background: $hf__color-gray-darker;

      .category-bar__list-wrap {
        background: $hf__color-gray-darker;
        border-top: 1px solid darken($grey, 15%);
      }
    }

    &--spec {
      background: $hf__color-gray-darker;

      .category-bar__list-wrap {
        background: $hf__color-gray-darker;
        border-top: 1px solid darken($grey, 5%);
      }
    }

    &__list {
      display: flex;
      flex-flow: column;
    }

    &__list-wrap {
      position: absolute;
      top: 100%;
      left: 0;
      /*width: 100%;*/
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: .3s ease-in 0s;

      &.is-visible {
        opacity: 1;
        visibility: visible;
      }
    }

    &__additional {
      display: flex;
      align-items: center;
      flex: 0;
      margin-left: auto;
    }

    &__clear-list {
      font-size: 0.8em;
      margin-right: $margin;
      white-space: nowrap;
    }

    &__item {
      flex: 0 0 auto;
      padding: $categoryBarItemPadding;
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: 600;

      > a, a.category-bar__item-anchor {
        display: flex;
        align-items: center;
        color: $white;

        &:hover {
          color: $grey-lighter;
        }

        &.is-active {
          font-weight: $weight-bold;
          color: $hf__color-primary--alternative;
        }
      }
    }

    &__muted-text {
      font-weight: $weight-semibold;
      text-transform: uppercase;
      color: $grey-lighter !important;
    }
  }

  @include tablet {
    margin-bottom: 4rem;

    .selected-category {
      display: none;
    }
    .category-bar {
      &__wrapper {
        display: flex;
      }

      &__list {
        flex-flow: row wrap;
      }

      &__item {
        &:first-of-type {
          padding-left: 0;
        }

        font-size: 1rem;
        padding: $padding;
        line-height: 1;
        font-weight: 700;
      }

      &__list-wrap {
        position: static;
        border: none !important;
        opacity: 1 !important;
        visibility: visible !important;
      }

      &__additional {
        & > *:last-of-type {
          .category-bar__item {
            padding-right: 0;
          }
        }
      }
    }
  }
}

</style>
