<template>
  <div :class="{'is-active': isActive}" class="StorySearchFiltersDropdown dropdown is-right">
    <div class="dropdown-trigger">
      <div class="has-nowrap category-bar__item category-bar__muted-text">
        <a
          ref="trigger"
          :class="{'is-active': language || country }"
          data-testid="StorySearchFiltersDropdownTrigger"
          class="category-bar__item-anchor"
          @click="toggleOpen"
        >
          {{ $t('components.story_search.filters') }}
        </a>
      </div>
    </div>
    <div
      v-click-away="clickedAway"
      class="dropdown-menu"
    >
      <div class="dropdown-content">
        <div
          v-if="isActive"
          class="dropdown-item"
        >
          <div class="columns">
            <div class="column">
              <h6 class="has-text-weight-bold m-b-m">
                {{ $t('components.story_search.filter_by_language') }}
              </h6>
              <el-select
                v-model="languageProp"
                :placeholder="$t('general.language')"
                class="StorySearchFilters__language"
                popper-class="StorySearchFilters__SelectLanguage"
                clearable
                filterable
              >
                <el-option
                  v-for="availableLanguage in availableLanguages"
                  :key="availableLanguage"
                  :label="$t('components.language_switcher.languages.' + availableLanguage)"
                  :value="availableLanguage"
                />
              </el-select>
            </div>
            <div class="column">
              <h6 class="has-text-weight-bold m-b-m">
                {{ $t('forms.country_select') }}
              </h6>
              <country-picker
                v-model="countryProp"
                clearable
                popper-class="StorySearchFilters__SelectCountry"
                class="StorySearchFilters__country"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Trans } from '@/services/TranslationService'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

export default {
  props: {
    language: {
      type: String,
      default: null
    },
    country: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    availableLanguages: () => Trans.supportedLanguages,
    languageProp: generateComputedSetterWithEmit('language', 'update:language'),
    countryProp: generateComputedSetterWithEmit('country', 'update:country')
  },
  methods: {
    toggleOpen () {
      this.isActive = !this.isActive
    },
    clickedAway (e) {
      if (e.target === this.$refs.trigger) return
      this.isActive = false
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

@include tablet {
  .StorySearchFiltersDropdown {
    .dropdown-menu {
      min-width: 450px;
    }
  }
}
</style>
