<template>
  <div :class="{'is-active': isActive}" class="NewsroomSearchDropdown dropdown is-right">
    <div class="dropdown-trigger">
      <div class="has-nowrap category-bar__item category-bar__muted-text">
        <a
          ref="trigger"
          :class="{'is-active': isActive}"
          data-testid="NewsroomSearchDropdownTrigger"
          class="category-bar__item-anchor"
          @click.prevent="toggleOpen"
        >
          <i class="hf hf-search hf-fw" /> <span> {{ $t('forms.search') }}</span>
        </a>
      </div>
    </div>

    <div
      v-click-away="clickedAway"
      class="dropdown-menu"
      style="min-width: 300px;"
    >
      <div class="dropdown-content">
        <div class="dropdown-item">
          <h5 class="title is-5">
            {{ $t('components.company_search.find_newsroom') }}
          </h5>

          <newsroomSearch
            ref="newsroomSearch"
            size="small"
            @change="handleChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newsroomSearch from '@/components/storySearch/NewsroomSearch'

export default {
  components: {
    newsroomSearch
  },

  data () {
    return {
      isActive: false
    }
  },

  methods: {
    toggleOpen () {
      this.isActive = !this.isActive
      if (this.isActive) this.$refs.newsroomSearch.focusSelect()
    },

    clickedAway (e) {
      if (e.target.parentElement === this.$refs.trigger) return
      this.isActive = false
    },

    handleChange (selected) {
      this.$router.push({
        name: 'newsroom.stories',
        params: { newsroomSlug: selected.slug }
      })
    }
  }
}
</script>
