<template>
  <div v-if="categories.length" class="category-bar">
    <div class="container no-margin:mobile">
      <div class="category-bar__wrapper">
        <div
          v-click-away="clickAwayClose"
          :class="{ 'is-visible': displayCategoriesDropdownOnMobile }"
          class="category-bar__list-wrap"
        >
          <ul class="category-bar__list">
            <li
              v-for="cat in categoriesToLoop"
              :key="cat.id"
              class="category-bar__item"
            >
              <a
                :class="{'is-active': isActiveCategory(cat) }"
                @click="preSelectCategory(cat)"
              >{{ $t('categories.'+cat.id ) }}</a>
            </li>
            <template v-if="showMore && splitCategories.theRest.length">
              <li class="category-bar__item is-hidden-mobile">
                <div
                  v-click-away="hideMoreDropDown"
                  :class="{ 'is-active': dropdownVisible }"
                  class="dropdown is-right"
                >
                  <a
                    :class="{ 'is-active': activeCategoriesInDropdown }"
                    class="category-bar__item-anchor"
                    @click="dropdownVisible = !dropdownVisible"
                  >
                    <span>{{ $t('components.story_search.more') }}</span>
                    <span class="icon is-small">
                      <i class="hf hf-angle-down" aria-hidden="true" />
                    </span>
                  </a>
                  <div class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <a
                        v-for="cat in splitCategories.theRest"
                        :key="cat.id"
                        :class="{'is-active': isActiveCategory(cat) }"
                        class="dropdown-item"
                        @click="preSelectCategory(cat)"
                      >
                        {{ $t('categories.'+cat.id ) }}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li
                v-for="cat in splitCategories.theRest"
                :key="cat.id"
                class="category-bar__item is-hidden-tablet"
              >
                <a
                  :class="{'is-active': isActiveCategory(cat) }"
                  @click="preSelectCategory(cat)"
                >
                  {{ $t('categories.'+cat.id ) }}
                </a>
              </li>
            </template>
          </ul>
        </div>
        <div class="category-bar__additional">
          <div
            :class="{ 'is-active': displayCategoriesDropdownOnMobile }"
            class="selected-category"
          >
            <a
              ref="trigger"
              :class="{ selected: selectedCategory }"
              class="selected-category__name"
              @click="toggleCategoriesMobileDropdown()"
            >
              <span v-if="selectedCategory">
                {{ $t('categories.'+selectedCategory.id ) }}
              </span>
              <span v-else>
                {{ placeholder }}
              </span>
            </a>
          </div>
          <div v-if="showClearList && selectedCategory" class="category-bar__clear-list">
            <a
              class="is-uppercase"
              data-testid="clearCategory"
              @click="clearCategory(selectedCategory.depth)"
            >
              <i class="hf hf-times hf-fw" />
              {{ $t('components.story_search.clear') }}
            </a>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => ([])
    },
    selectedCategory: {
      type: Object,
      default: null
    },
    selectCategory: {
      type: Function,
      required: true
    },
    showMore: {
      type: Boolean,
      default: false
    },
    showClearList: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Select Category'
    },
    clearCategory: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      dropdownVisible: false,
      displayCategoriesDropdownOnMobile: false
    }
  },
  computed: {
    splitCategories () {
      const firstBatch = this.categories.slice(0, 5)
      const theRest = this.categories.slice(5)
      return {
        firstBatch,
        theRest
      }
    },
    categoriesToLoop () {
      return this.showMore ? this.splitCategories.firstBatch : this.categories
    },
    activeCategoriesInDropdown () {
      return this.selectedCategory ? this.splitCategories.theRest.find(c => c.id === this.selectedCategory.id) : false
    }
  },
  mounted () {
    this.$bus.$on('StorySearchCategoryBar:hideAllCategoriesDropdown', () => {
      this.displayCategoriesDropdownOnMobile = false
    })
  },
  methods: {
    hideMoreDropDown () {
      this.dropdownVisible = false
    },
    toggleCategoriesMobileDropdown (isVisible = !this.displayCategoriesDropdownOnMobile) {
      isVisible && this.$bus.$emit('StorySearchCategoryBar:hideAllCategoriesDropdown')
      this.$nextTick(() => {
        this.displayCategoriesDropdownOnMobile = isVisible
      })
    },
    isActiveCategory (cat) {
      return (this.selectedCategory && cat.id === this.selectedCategory.id)
    },
    preSelectCategory (cat) {
      this.dropdownVisible = false
      this.displayCategoriesDropdownOnMobile = false
      this.selectCategory(cat)
    },
    clickAwayClose (el) {
      if (this.$refs.trigger.contains(el.target)) return
      this.displayCategoriesDropdownOnMobile = false
    }
  }
}
</script>
