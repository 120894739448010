<template>
  <div class="company-search-bar">
    <el-select
      ref="select"
      v-model="chosen"
      :placeholder="$t('components.company_search.enter_keyword')"
      :size="size"
      :remote-method="searchForNewsroom"
      :loading="isLoading"
      class="NewsroomSearch__Select"
      filterable
      remote
      value-key="id"
      popper-class="NewsroomSearch__Dropdown"
      @change="changeHandler"
    >
      <el-option
        v-for="newsroom in options"
        :key="newsroom.id"
        :label="newsroom.name"
        :value="newsroom"
        class="is-height-auto"
      >
        <div class="columns is-marginless company-search-bar__item">
          <div class="column is-narrow">
            <round-avatar
              :src="newsroom.logo.cropped"
              :main-color="newsroom.main_color"
              size="small"
            />
          </div>
          <div class="column">
            {{ newsroom.name }}
          </div>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

export default {
  mixins: [debounceMixin(['searchForNewsroom']), 250],

  props: {
    size: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      chosen: null,
      options: [],
      isLoading: false
    }
  },

  methods: {
    searchForNewsroom (searchValue) {
      if (searchValue === '') {
        this.options = []
        return
      }
      this.isLoading = true
      NewsroomService.findNewsrooms(searchValue)
        .then(response => {
          this.isLoading = false
          this.options = response
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error)
        })
    },
    changeHandler (selected) {
      this.$emit('change', selected)
    },
    focusSelect () {
      this.$nextTick(() => {
        this.$refs.select.focus()
      })
    }
  }
}
</script>
<style scoped lang="scss">
.company-search-bar {
  &__item {
    /*margin-bottom: .5rem;*/
  }
}
</style>
