<template>
  <div class="stories">
    <story-search
      ref="search"
      :query-limit="20"
      @paginationChange="paginationChangeHandler"
    />

    <div class="container no-margin:mobile">
      <section class="stories-list">
        <div
          v-if="hasStories"
          class="columns is-variable-grid is-1 is-multiline"
        >
          <div
            v-for="(story, index) in stories"
            :key="story.id"
            :class="storyWrapExtraClass(index)"
            class="column is-3-tablet is-column"
          >
            <story-card
              :key="story.id"
              :story="story"
              :is-larger="index < 2"
              :hide-content="index !== 1"
              :copy-text-chars="index === 1 ? 530 : undefined"
              :class="storyExtraClass(index)"
            />
          </div>
        </div>

        <!--<transition name="fade">-->
        <!--<div v-if="isFetchingStories" key="fetchingStoriesMessage">-->
        <!--<div class="has-text-centered is-size-5">-->
        <!--<i class="hf hf-spinner hf-pulse"></i> Fetching stories...-->
        <!--</div>-->
        <!--</div>-->
        <!--</transition>-->

        <div v-show="showLoadMore" class="load-more has-text-centered m-v-l">
          <button
            :class="{'is-loading': isFetchingStories}"
            class="button is-primary"
            @click="loadMore"
          >
            {{ $t('components.load_more.load_more') }}
          </button>
        </div>

        <div
          v-if="!isFetchingStories && !hasStories"
          class="no-content-available has-text-centered is-uppercase has-text-muted"
        >
          {{ $t('components.story_search.no_stories_match_your_search_criteria') }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import StorySearch from '@/components/storySearch/StorySearch.vue'
import StoryCard from '@/components/storySearch/StoryCard.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Stories',

  components: {
    StorySearch,
    StoryCard
  },

  data () {
    return {
      showLoadMore: false
    }
  },

  computed: {
    ...mapGetters({
      selectedCategories: 'selectedStoryCategories',
      allCategories: 'allStoryCategories',
      isFetchingStories: 'isFetchingStories',
      specCategories: 'specCategories',
      mainSelectedCategory: 'mainSelectedCategory',
      specSelectedCategory: 'specSelectedCategory',
      stories: 'storiesList'
    }),

    hasStories () {
      return this.stories.length > 0
    },

    storiesListTitle () {
      if (this.mainSelectedCategory) {
        return this.$t('categories.' + this.mainSelectedCategory.id)
      }

      return this.$t('pages.stories.read_news')
    },

    storiesListSubtitle () {
      if (this.mainSelectedCategory) {
        return this.$t('pages.stories.category_desc.' + this.mainSelectedCategory.id)
      }

      return this.$t('pages.stories.discover_articles')
    }
  },

  beforeMount () {
    this.setStories([])
  },

  methods: {
    ...mapMutations({ setStories: 'SET_STORIES' }),

    selectCategory (category) {
      this.$refs.search.selectCategory(category)
    },

    isActiveSubcategory (cat) {
      return this.specSelectedCategory && this.specSelectedCategory.id === cat.id
    },

    storyWrapExtraClass (index) {
      return {
        'is-8-desktop is-large-story': index === 0,
        'is-4-desktop': index === 1,
        'is-one-fourth-desktop': index > 1
      }
    },

    storyExtraClass (index) {
      return {
        'is-black': (index - 1) % 3 === 0,
        'has-background-grey-light': (index - 2) % 6 === 0
      }
    },

    paginationChangeHandler (pagination) {
      this.showLoadMore = pagination.hasMore
    },

    loadMore () {
      this.$refs.search.loadNext()
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.stories-list-description {
  border-top: 1px solid $primary;
  padding-top: $hf__card-padding;

  .title {
    color: $primary;
    font-size: 3rem;
    font-family: $font-family-serif;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .subtitle {
    font-size: 1.3rem;
  }

  .menu li a {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}
</style>
